<script>
  import {
    StarFilledRound,
    AmazonStore,
    ApplePlayStore,
    GooglePlayStore,
  } from '@/components/icons'

  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import ReadMore from '../../ReadMore.svelte'

  export let darkTheme
  const comments = [
    {
      name: 'Neil Ski',
      date: 'April 20',
      title: 'Get one as soon as you can!',
      description:
        'They have sales but even if it were not on sale get one anyway. You can scan your friends car and show them how sweet this little device really is!!! Now when you use it on your own vehicles is where you save money and piece of mind. You can put in your mileage and turn on alerts, it will alert you when to change your oil, and the big thing is preventative maintenance. You will realize the second time you use it, you made one of the best purchases ever!!! I used this little device a lot and not having the complete package served it’s purpose but now I am going to upgrading to get all the services!!! I should copyright this phrase, Just get it You won’t regret it!!!',
      icon: ApplePlayStore,
      source: 'Apple App Store',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'apple',
    },
    {
      name: 'Fernando Diaz',
      date: 'June 16, 2023',
      title: null,
      description: 'The best of the Best',
      icon: GooglePlayStore,
      source: 'Google Play',
      url: 'https://play.google.com/store/apps/details?id=com.fixdapp.two&hl=en_US&reviewId=f151c900-1634-4b1e-954d-67f73c23de95&adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'google',
    },
    {
      name: 'onenonly8714',
      date: 'May 25, 2023',
      title: 'Review for my Honda Accord LX',
      description:
        'Well for a woman I like to keep my cars up to date with evrything bcuz I always have my kids or friends in my car and I love going places. When I bought the FIXD device thing, it was a game changer. I didnt need to beg any man in my family to please help me out with my car. Getting this and setting it up myself was so easy and it explains evrything perfectly to my understanding. I am definitely recommending evryone in the entire world should invest in getting one of these things. U will not regret it💯',
      icon: ApplePlayStore,
      source: 'Apple App Store',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'apple',
    },
    {
      name: 'Cody Douglas',
      date: 'February 25, 2023',
      title: 'Absolutely love this product 🙌',
      description:
        "Hands down the best product for your car suv truck whatever I have a 2001 chevy and it just sits in my car all day everyday like the best product for your car if u have a issue 😉 highly recommend this product over all others 5star forsure and it's so cheap now I got it when it was still 35 now its 25",
      icon: AmazonStore,
      source: 'Amazon',
      url: 'https://apps.apple.com/us/app/fixd-obd2-scanner/id957168651?adb_sid=be45ef67-943b-4e85-ad7d-7020fa7e2b0b',
      type: 'amazon',
    },
  ]

  $: currentType = 'all'
  $: filteredComments =
    currentType === 'all'
      ? comments
      : comments.filter((x) => x.type === currentType)

  const filterComments = (type) => {
    currentType = type
  }

  const filterButtons = [
    {
      type: 'all',
      icon: null,
      text: 'All Reviews',
      rating: 4.6,
    },
    {
      type: 'apple',
      icon: ApplePlayStore,
      text: 'Apple App Store',
      rating: 4.6,
    },
    {
      type: 'amazon',
      icon: AmazonStore,
      text: 'Amazon',
      rating: 4.6,
    },
    {
      type: 'google',
      icon: GooglePlayStore,
      text: 'Google Play',
      rating: 4.6,
    },
  ]
</script>

<div class="rounded max-w-[900px] mx-auto">
  <div class="bg-green rounded-t-lg py-0.5 font-inter text-sm">
    <div class="max-w-screen-md mx-auto">
      <Carousel
        showPagination={false}
        showArrows={false}
        arrowColor="white"
        splideOptions={{
          classes: 'flex',
          perPage: 4,
          width: '768px',
          padding: 32,
          breakpoints: {
            768: {
              perPage: 2,
              fixedWidth: 170,
              gap: '16px',
              arrows: true,
            },
          },
        }}
      >
        {#each filterButtons as button}
          <CarouselItem>
            <div
              class="flex items-center justify-center"
              on:click={() => filterComments(button.type)}
              on:keydown={() => filterComments(button.type)}
              role="button"
              tabindex="-1"
            >
              <div
                class="py-2 border-b-2 flex items-center gap-3"
                class:border-transparent={currentType !== button.type}
              >
                {#if button.icon}
                  <svelte:component
                    this={button.icon}
                    color="black"
                    size="md"
                  />
                {/if}
                <p class="text-white">
                  {button.text}
                  <span class="font-semibold">{button.rating}</span>
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
  </div>
  <div
    class="rounded text-left p-6 mb-5 {darkTheme
      ? 'bg-[#000000]'
      : 'bg-[#f3f3f3]'}"
  >
    <p class="text-xl mb-1">Overall Rating</p>
    <div class="flex items-center gap-3">
      <p class="text-xl font-bold">4.6</p>
      <div class="flex">
        {#each new Array(5) as _}
          <StarFilledRound color="yellow" size="md" />
        {/each}
      </div>
      <p class="text-xs text-grey">46,903 reviews</p>
    </div>
  </div>
</div>

<div class="space-y-5 max-w-[900px] mx-auto">
  {#each filteredComments as comment}
    <div class="rounded p-6 {darkTheme ? 'bg-[#000000]' : 'bg-[#f3f3f3]'}">
      <header class="text-left">
        <div class="mb-3">
          <a
            href={comment.url}
            class="font-semibold text-sm no-underline hover:underline text-black"
            >{comment.name}</a
          >
          <p class="text-xs">{comment.date}</p>
        </div>
        <div class="flex mb-2">
          {#each new Array(5) as _}
            <StarFilledRound color="yellow" size="md" />
          {/each}
        </div>
      </header>
      <div class="text-center leading-6 mb-3">
        {#if comment.title}
          <p class="font-semibold">{comment.title}</p>
        {/if}
        <p class:text-left={!comment.title}>
          <ReadMore text={comment.description} length={325} />
        </p>
      </div>
      <a
        class="text-left no-underline group"
        href={comment.url}
        target="_blank"
        rel="noreferrer"
      >
        <div class="flex items-center">
          <svelte:component this={comment.icon} color={'green'} size="xl" />
          <div class="leading-5 text-center ml-3">
            <p class="text-grey text-xs">Posted on</p>
            <p class="text-green text-sm group-hover:underline">
              {comment.source}
            </p>
          </div>
        </div>
      </a>
    </div>
  {/each}
</div>
